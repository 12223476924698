// import type { Plugin } from 'vue'
import { isIP } from '@/utils/is'

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any
  comp.install = (app: any) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
  if (!str) return ''
  return str.replace(/\-(\w)/g, (_, letter: string) => {
    return letter.toUpperCase()
  })
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
  dom.style.setProperty(prop, val)
}

export const getCssVar = (prop: string, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(prop)
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
// eslint-disable-next-line
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn)
  }
  let index = -1
  ary.some((item: T, i: number, ary: Array<T>) => {
    const ret: T = fn(item, i, ary)
    if (ret) {
      index = i
      return ret
    }
  })
  return index
}

export const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * 本地时间转为北京时间
 * @param date 本地时间
 * @param offset 时区偏移量
 * @returns
 */
export function convertLocalToBeijing(date: Date, offset: number = -480) {
  const localOffset = date.getTimezoneOffset() * 60 * 1000 // 获取本地时间的 UTC 偏移量（分钟）
  const beijingOffset = offset * 60 * 1000 // 北京时间的 UTC 偏移量（分钟）
  date = new Date(date.getTime() + (localOffset - beijingOffset)) // 计算北京时间的
  return date
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string) {
  if (!time) return ''
  else {
    const date = convertLocalToBeijing(new Date(time)) // 计算北京时间的
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}
/**北京时间的时间戳转北京时间 */
export function formatDateToBeijingTime(timestamp: number) {
  const beijingOffset = 8 * 3600 * 1000 // 8小时的毫秒数
  const date = new Date(timestamp + beijingOffset)
  const formattedDate = date
    .toISOString() //2024-07-16T08:43:39.000Z
    .replace(/T/g, ' ') //2024-07-16 08:43:39.000Z
    .replace(/\.\d{3}Z/, '') ////2024-07-16 08:43:39
  return formattedDate
}
/**时间戳 转星期几 */
export function formatWeek(time: number | string) {
  const date = convertLocalToBeijing(new Date(time)) // 计算北京时间的
  const week = ['日', '一', '二', '三', '四', '五', '六']
  return '星期' + week[date.getDay()]
}
//** 零点时间戳函数*/
export function getZeroTimestamp(time: number): number {
  const date = convertLocalToBeijing(new Date(time * 1000)) // 计算北京时间的
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const dateStr = year + '/' + (month + 1) + '/' + day + ' 00:00:00 UTC+0800'
  // console.log(dateStr)
  return Math.ceil(Date.parse(dateStr) / 1000)
}
/**获取上个月1号的函数 */
export const getLastMonthFirstDay = () => {
  const date = new Date()
  date.setDate(1)
  date.setMonth(date.getMonth() - 1)
  return date
}
// export function getUTC8Time(time: string, fmt: string) {
//   const date = new Date(time)
//   const year = date.getFullYear()
//   const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
//   const day = date.getDate()
//   const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
//   const minnutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
//   const seconds = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()

//   const dateStr = `${year}-${month}-${day} ${hours}:${minnutes}:${seconds} UTC+0800`
//   console.log(dateStr, Date.parse(dateStr))
//   return formatTime(Date.parse(dateStr), fmt)
// }

/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0
    const v: number = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString()
  })
  return str
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
  const formData = new FormData()
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key])
  })
  return formData
}

/**ip转数字 */
export const ipToNum = (ip: string) => {
  if (isIP(ip)) {
    const arr = ip.split('.')
    const num =
      parseInt(arr[0]) * 256 * 256 * 256 +
      parseInt(arr[1]) * 256 * 256 +
      parseInt(arr[2]) * 256 +
      parseInt(arr[3])
    console.log(num)
    return num
  } else {
    alert('绑定登录IP地址格式不正确')
    return 0
  }
}
/**数字转ip */
export const numToIp = (num: number) => {
  const tt = num % 256
  const ss = ((num - tt) / 256) % 256
  const qq = ((num - tt - ss * 256) / 256 / 256) % 256
  const ww = ((num - tt - ss * 256 - qq * 256 * 256) / 256 / 256 / 256) % 256
  const ip = ww + '.' + qq + '.' + ss + '.' + tt
  console.log(ip)
  return ip
}

// json转arraybuffer
// 定义一个 jsontoArrayBuffer 函数
export const stringToArrayBuffer = (data: string) => {
  // 将字符串转换为 UTF-8 编码的字节序列
  const bytes = new TextEncoder().encode(data)
  // 创建一个新的 ArrayBuffer，并将字节序列复制到其中
  const buffer = new ArrayBuffer(bytes.length)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < bytes.length; i++) {
    view[i] = bytes[i]
  }
  // 返回 ArrayBuffer
  return buffer
}

// arrayBufferToString 函数
export const arrayBufferToString = (buffer): string => {
  // 创建一个新的 Uint8Array，并将 ArrayBuffer 复制到其中
  const view = new Uint8Array(buffer)
  // 将 Uint8Array 转换为字符串
  const decoder = new TextDecoder('utf-8')
  return decoder.decode(view)
}

/**
 * 时间戳转倒计时
 * @param time 时间戳(毫秒)
 * @returns
 */
export const formatCountdown = (time: number) => {
  const day = Math.floor(time / (24 * 3600 * 1000))
  const hour = Math.floor((time % (24 * 3600 * 1000)) / (3600 * 1000))
  const minute = Math.floor((time % (3600 * 1000)) / (60 * 1000))
  const second = Math.floor((time % (60 * 1000)) / 1000)
  let str = ''
  if (day) {
    str += `${day}天`
  }
  if (hour) {
    if (hour < 10) {
      str += `0${hour}:`
    } else {
      str += `${hour}:`
    }
  }
  if (minute) {
    if (minute < 10) {
      str += `0${minute}:`
    } else {
      str += `${minute}:`
    }
  } else {
    str += `00:`
  }
  if (second) {
    if (second < 10) {
      str += `0${second}`
    } else {
      str += `${second}`
    }
  } else {
    str += '00'
  }
  return str
}

// 十进制数字转二进制后，按位转成十进制整数数组
export const intToIntArray = (num: number) => {
  const f = [] as number[]
  const a = num.toString(2)
  for (let i = a.length - 1, j = 0; i >= 0; --i, ++j) {
    if (a[i] === '1') {
      // const c = parseInt(Math.pow(10, j).toString(), 2)
      let c = Math.pow(10, j)
      c = parseInt(c.toLocaleString('fullwide', { useGrouping: false }), 2)
      const d = parseInt(c.toString(10))
      if (!isNaN(d)) {
        f.push(d)
      }
    }
  }
  return f
}
/**
 * // 将一个长数组拆分成多个小数组
 * @param array 长数组
 * @param len 小数组长度
 * @returns 返回小数组
 */
export function getCombinations(array: number[], len: number): number[][] {
  const combinations: number[][] = []
  const currentCombination: number[] = []

  function generateCombinations(startIndex: number, remainingLength: number): void {
    if (remainingLength === 0) {
      combinations.push([...currentCombination])
      return
    }

    for (let i = startIndex; i < array.length; i++) {
      currentCombination.push(array[i])
      generateCombinations(i + 1, remainingLength - 1)
      currentCombination.pop()
    }
  }

  generateCombinations(0, len)
  return combinations
}
/**获取终端类型 */
export const checkClient = () => {
  const browser = {
    versions: (function () {
      const u = navigator.userAgent
      return {
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        weixin: u.indexOf('MicroMessenger') > -1 //是否微信 （2015-01-22新增）
      }
    })()
  }
  if (browser.versions.android) {
    return 'android'
  }
  //判断是否webKit内核
  if (browser.versions.ios) {
    return 'ios'
  }
  return 'other'
}
